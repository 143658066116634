<template>
    <div class="wrap">
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <div class="space_pc">
            <section class="main event">
                <div class="main_box">
                    <h1>이벤트</h1>
                    <div class="swiper-wraaper">
                        <div class="one_depth">
                            <a class="one_depth_btn" :class="{ on: ongoing === true }" style="cursor: pointer" @click="ongoing = true"
                            >진행중 이벤트<span class="event_count">{{ eventList.length }}</span></a
                            >
                        </div>
                        <div class="one_depth">
                            <a
                                class="one_depth_btn"
                                :class="{ on: ongoing === false }"
                                style="cursor: pointer"
                                @click="ongoing = false"
                            >종료된 이벤트<span class="event_count">{{ endEventList.length }}</span></a
                            >
                        </div>
                    </div>
                    <div class="wrap-events-thumb-list" v-if="ongoing">
                        <div class="wrap-events-empty" v-if="eventList.length === 0">
                            <div class="empty_area">
                                <img src="/media/img/icon_empty_event_52.png" alt="" />
                                <p>진행 중인 이벤트가 없습니다.</p>
                            </div>
                        </div>
                        <div v-else>
                            <template v-for="(item, index) in eventList">
                                <ul style="padding-left: 0px" :key="index">
                                    <li @click="linkEventView(item)">
                                        <div class="end-day" v-if="endDay(item.eventEndDate) >= 0">D-{{ endDay(item.eventEndDate) }}</div>
                                        <div class="end-day finished" v-else>종료</div>
                                        <figure style="margin-bottom: 0px" v-if="item.eventThumImg">
                                            <img :src="baseUrl + item.eventThumImg.substr(5)" alt="" />
                                        </figure>
                                        <div class="wrap-event-content">
                                            <h2>{{ item.eventTitle }}</h2>
                                            <p style="margin-bottom: 0px">
                                                <time>{{ dateTime(item.eventStartDate) }} ~ {{ dateTime(item.eventEndDate) }}</time>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                    <div class="wrap-events-thumb-list" v-else>
                        <div class="wrap-events-empty" v-if="endEventList.length === 0">
                            <div class="empty_area">
                                <img src="/media/img/icon_empty_event_52.png" alt="" />
                                <p>진행 중인 이벤트가 없습니다.</p>
                            </div>
                        </div>
                        <div v-else>
                            <template v-for="(item, index) in endEventList">
                                <ul style="padding-left: 0px" :key="index">
                                    <li @click="linkEventView(item)">
                                        <div class="end-day" v-if="endDay(item.eventEndDate) >= 0">D-{{ endDay(item.eventEndDate) }}</div>
                                        <div class="end-day finished" v-else>종료</div>
                                        <figure style="margin-bottom: 0px" v-if="item.eventThumImg">
                                            <img :src="baseUrl + item.eventThumImg.substr(5)" alt="" />
                                        </figure>
                                        <div class="wrap-event-content">
                                            <h2>{{ item.eventTitle }}</h2>
                                            <p style="margin-bottom: 0px">
                                                <time>{{ dateTime(item.eventStartDate) }} ~ {{ dateTime(item.eventEndDate) }}</time>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo hauto">
            <header class="header mo_header">
                <div class="header_box">
                    <div class="logo">
                        <a @click="linkPlayer()">
                            <img src="/media/images/icon_back_24.svg" />
                            <h2>이벤트</h2>
                        </a>
                    </div>
                    <div class="right_menu">
                        <div class="alarm_box" @click="mobileAlarm()">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M2.00024 18.666H20.3786"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <ellipse
                                    cx="17.6756"
                                    cy="6.44444"
                                    rx="4.32431"
                                    ry="4.44444"
                                    fill="#FF1150"
                                    v-if="$store.state.noticeCount !== 0"
                                />
                            </svg>
                        </div>
                        <div class="toggle">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="showAside()"
                            >
                                <g clip-path="url(#clip0_2231_20993)">
                                    <path
                                        d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2231_20993">
                                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="swiper-wraper">
                    <a class="one_depth" :class="{ on: ongoing === true }" style="cursor: pointer" @click="ongoing = true"
                    >진행중 이벤트<span class="event_count">{{ eventList.length }}</span></a
                    >
                    <a class="one_depth" :class="{ on: ongoing === false }" style="cursor: pointer" @click="ongoing = false"
                    >종료된 이벤트<span class="event_count">{{ endEventList.length }}</span></a
                    >
                </div>
            </header>
            <section class="main event">
                <div class="main_box">
                    <h1>이벤트</h1>
                    <div class="wrap-events-thumb-list" v-if="ongoing">
                        <div class="wrap-events-empty" v-if="eventList.length === 0">
                            <div class="empty_area">
                                <img src="/media/img/empty_event.png" alt="" />
                                <p>진행 중인 이벤트가 없습니다.</p>
                            </div>
                        </div>
                        <div v-else>
                            <template v-for="(item, index) in eventList">
                                <ul :key="index">
                                    <li @click="linkEventView(item)">
                                        <div class="end-day" v-if="endDay(item.eventEndDate) >= 0">D-{{ endDay(item.eventEndDate) }}</div>
                                        <div class="end-day finished" v-else>종료</div>
                                        <figure style="margin-bottom: 0px" v-if="item.eventThumImg">
                                            <img :src="baseUrl + item.eventThumImg.substr(5)" alt="" />
                                        </figure>
                                        <div class="wrap-event-content">
                                            <h2>{{ item.eventTitle }}</h2>
                                            <p style="margin-bottom: 0px">
                                                <time>{{ dateTime(item.eventStartDate) }} ~ {{ dateTime(item.eventEndDate) }}</time>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                    <div class="wrap-events-thumb-list" v-else>
                        <div class="wrap-events-empty" v-if="endEventList.length === 0">
                            <div class="empty_area">
                                <img src="/media/img/icon_empty_event_52.png" alt="" />
                                <p>진행 중인 이벤트가 없습니다.</p>
                            </div>
                        </div>
                        <div v-else>
                            <template v-for="(item, index) in endEventList">
                                <ul :key="index">
                                    <li @click="linkEventView(item)">
                                        <div class="end-day" v-if="endDay(item.eventEndDate) >= 0">D - {{ endDay(item.eventEndDate) }}</div>
                                        <div class="end-day finished" v-else>종료</div>
                                        <figure style="margin-bottom: 0px" v-if="item.eventThumImg">
                                            <img :src="baseUrl + item.eventThumImg.substr(5)" alt="" />
                                        </figure>
                                        <div class="wrap-event-content">
                                            <h2>{{ item.eventTitle }}</h2>
                                            <p style="margin-bottom: 0px">
                                                <time>{{ dateTime(item.eventStartDate) }} ~ {{ dateTime(item.eventEndDate) }}</time>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import dayjs from 'dayjs';
import Aside from '@/layout/aside/Aside.vue';

export default {
  name: 'eventList',
  data () {
    return {
      eventList: [],
      endEventList: [],
      baseUrl: `${process.env.VUE_APP_BASE_URL}/media`,
      limit: 100,
      ongoing: true,
      asideStatus: false
    };
  },
  components: {
    Aside
  },
  created () {
    this.getEventList();
  },
  methods: {
    linkPlayer () {
      const spaceId = VueCookies.get('spaceId');
      if (spaceId != null) {
        router.push({ name: 'Player' });
      } else {
        router.push({ name: 'Main' });
      }
    },
    mobileAlarm () {
      router.push({ name: 'Notice0' });
    },
    clickPre () {
      history.back();
    },
    showAside () {
      this.asideStatus = true;
    },
    endDay (value) {
      const today = dayjs();
      const expireDate = dayjs(value);
      const endDay = expireDate.diff(today, 'day', true);
      return Math.floor(endDay);
    },
    startDay (value) {
      const today = dayjs();
      const startDate = dayjs(value);
      const startDay = today.diff(startDate, 'day', true);
      return Math.floor(startDay);
    },
    dateTime (value) {
      return dayjs(value).format('YYYY.MM.DD');
    },
    linkEventView (event) {
      this.$store.commit('setEventId', event.eventId);
      router.push({ name: 'EventView' });
    },
    getEventList () {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const limit = this.limit;
      const offset = 0;
      axios
        .post('/api/menu/getEventList', { limit, offset }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const list = res.data.result.eventList;
            for (let i = 0; i < list.length; i++) {
              // console.log(this.startDay(list[i].eventStartDate));
              if (this.startDay(list[i].eventStartDate) >= 0 && this.endDay(list[i].eventEndDate) >= 0) {
                this.eventList.push(list[i]);
              } else if (this.endDay(list[i].eventEndDate) < 0) {
                this.endEventList.push(list[i]);
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.end-day.finished {
  background: #888888;
  color: #fff;
}

.wrap-events-thumb-list ul li {
  cursor: pointer;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
